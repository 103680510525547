import * as Yup from "yup";
import moment from "moment";

import { PosTypes } from "Constants";
import { TranslateFunc } from "Providers";

export const getSchema = (
    isFixedDiscount: boolean,
    alreadyUsedCodes: Set<string>,
    translate: TranslateFunc
): object => {
    if (isFixedDiscount) {
        return Yup.object().shape({
            name: Yup.string().required(translate("mustFillIn")),
            rate: Yup.number().test("rate_higher_than_zero", translate("fillThisOut"), function (value: number) {
                return value > 0;
            }),
            eatingOptions: Yup.array().when("availableOn", {
                is: on => on.includes(PosTypes.ONLINE),
                then: Yup.array().min(1, translate("mustSelectAtLeastOne"))
            }),
            shopIds: Yup.array().required(translate("mustSelectAtLeastOne")),
            selectedMenuIds: Yup.array()
                .min(1, translate("mustChooseAtLeastOneMenu"))
                .required(translate("mustChooseAtLeastOneMenu")),
            startDate: Yup.date()
                .required(translate("mustChooseAStartDate"))
                .test("validateStartDate", translate("mustChooseAStartDate"), (value: moment.Moment) => {
                    return moment(value).isValid();
                }),
            endDate: Yup.date()
                .required(translate("mustChooseAnEndDate"))
                .test("validateEndDate", translate("mustChooseAnEndDate"), (value: moment.Moment) => {
                    return moment(value).isValid();
                })
        });
    } else {
        return Yup.object().shape(
            {
                name: Yup.string().required(translate("mustFillIn")),
                code: Yup.string()
                    .test("code_no_Spaces", translate("noSpaces"), val => !(val && val.includes(" ")))
                    .test("code_already_Used", translate("onlineCodeHasBeenUsed"), (value: string) => {
                        return !alreadyUsedCodes.has(value);
                    })
                    .test("code_online_selected", translate("fillInThis"), function (value: string) {
                        if (!value.length) {
                            const hasOnlineOrExpressV2 = this.parent.availableOn?.some(
                                (pos: PosTypes) => pos === PosTypes.ONLINE || pos === PosTypes.EXPRESS
                            );
                            return !hasOnlineOrExpressV2;
                        }
                        return true;
                    })
                    .test("code_email_discount", translate("mustChooseOnlineAndCode"), function (value: string) {
                        if (!value.length) {
                            return !(this.parent.allowedEmails?.length > 0 || this.parent.oneEmailLimit);
                        }
                        return true;
                    }),
                amount: Yup.number()
                    .when(["rate"], {
                        is: rate => rate === 0,
                        then: Yup.number().required(translate("fillInThisOrPercentage"))
                    })
                    .test("amount_greater_than", translate("fillInThisOrPercentage"), function (value) {
                        const rate = this.parent.rate;

                        if (rate > 0) {
                            return value >= 0;
                        } else {
                            return value > 0;
                        }
                    }),
                rate: Yup.number()
                    .when(["amount"], {
                        is: amount => amount > 0,
                        then: Yup.number().required(translate("fillInThisOrAmount"))
                    })
                    .test("rate_greater_than_or_zero", translate("fillInThisOrAmount"), function (value) {
                        const amount = this.parent.amount;

                        if (amount > 0) {
                            return value >= 0;
                        } else {
                            return value > 0;
                        }
                    }),
                availableOn: Yup.array()
                    .required(translate("mustSelectAtLeastOne"))
                    .test("online_with_code", translate("mustChooseOnlineOrExpress"), function (value: PosTypes[]) {
                        return this.parent.code.length
                            ? value.some(
                                  (posType: PosTypes) => posType === PosTypes.ONLINE || posType === PosTypes.EXPRESS
                              )
                            : true;
                    }),
                allowedEmails: Yup.array()
                    .test("online_code_email", translate("mustFillInDiscountCode"), function (value: string[]) {
                        if (!this.parent.code.length && value.length > 0) {
                            return false;
                        }
                        return true;
                    })
                    .nullable(),
                eatingOptions: Yup.array().when("availableOn", {
                    is: on => on.includes(PosTypes.ONLINE),
                    then: Yup.array().min(1, translate("mustSelectAtLeastOne"))
                }),
                shopIds: Yup.array().required(translate("mustSelectAtLeastOne")),
                canApplyOnWholeOrder: Yup.boolean().when(["amount", "canApplyOnEachProduct"], {
                    is: (amount, eachProdct) => amount && !eachProdct,
                    then: Yup.boolean().oneOf([true], "*").required("*")
                }),
                startDate: Yup.date()
                    .required(translate("mustChooseAStartDate"))
                    .test("validateStartDate", translate("mustChooseAStartDate"), (value: moment.Moment) => {
                        return moment(value).isValid();
                    })
            },
            [["amount", "rate"]]
        );
    }
};
